import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router';
import { UserPermissions } from 'types/userPermissions';

export const usePermissions = () => {
  const { user } = useAuth();
  const { appId, logId } = useParams();

  const routes = [
    {
      path: '/privacy',
      resource: 'privacy'
    },
    {
      path: '/dashboard',
      resource: 'dashboard'
    },
    {
      path: '/app-errors',
      resource: 'apps/errors'
    },
    {
      path: `/app-errors/${appId}/${logId}`,
      resource: 'apps/errors'
    },
    {
      path: '/notifications',
      resource: 'notifications'
    },
    {
      path: '/settings',
      resource: 'settings'
    }
  ];

  const formatPermission = (resource: string | undefined, type?: string, building?: boolean) => {
    let res: undefined | string = resource;

    if (resource && type === 'route') {
      let el = routes.find((route) => route.path === resource);
      if (el) {
        res = el.resource;
      }
      if (resource.includes('/users')) {
        res = resource.includes('password') ? 'users.password' : 'users';
      }
      if (resource.includes('/app-errors')) {
        res = 'apps/errors';
      }
    }

    return res;
  };

  const checkPermission = (
    resource: string | undefined,
    permission: string | string[] | undefined = 'read',
    options?: { type?: string }
  ) => {
    let auth: boolean = false;
    const resourceToCheck = formatPermission(resource, options?.type, false);

    let userPermission: any;
    let userPermissions: UserPermissions[] = [];

    userPermissions = user.permissions?.authResources;

    userPermission = userPermissions?.find((res: any) => res.resource === resourceToCheck);
    auth = userPermission?.permissions?.includes(permission) ?? user.super;

    if (resource?.includes('/me')) auth = true;
    return auth;
  };

  return {
    checkPermission
  };
};
