import axios from 'axios';
import config from 'config';
//import dayjs from 'dayjs';
import { VariantType } from 'notistack';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';
import useAuth from './useAuth';

const axiosErrors = axios.create({
  baseURL: config.serverUrl + 'server/' + config.APIVersion
});

axiosErrors.defaults.headers.post['Content-Type'] = 'application/json';

export const useError = () => {
  const dispatch = useDispatch();
  //const location = window.location;

  const { logout } = useAuth();

  const handleError = (error: any) => {
    console.log(config.environment);

    // if (mode !== 'development') {
    // if (false) {
    //   axiosErrors({
    //     method: 'post',
    //     url: 'error',
    //     data: {
    //       componentStack: undefined,
    //       stack: error.stack as string,
    //       message: (error as any).response?.data?.message ?? error.message,
    //       status: (error as any).response?.data?.statusCode ?? undefined,
    //       agent: navigator.userAgent,
    //       at: dayjs().toDate(),
    //       userUrl: location.href
    //     }
    //   })
    //     .then((res) => {
    //       console.log('Errore inviato: ', error);
    //     })
    //     .catch((err) => {
    //       console.log('Errore inviato: ', error);
    //     });
    // }

    let errorVariant: VariantType = 'error',
      message: string = "C'è stato un errore nel caricamento della risorsa.";

    switch (error.response?.status) {
      case 404:
        message = 'Risorsa non trovata.';
        errorVariant = 'error';
        break;
      case 400:
        message = Array.isArray(error.response.data.message) ? error.response.data.message : 'Dati inviati non corretti.';
        // message = 'Dati inviati non corretti.'
        errorVariant = 'warning';
        break;
      case 401:
        localStorage['token'] = 'null';
        localStorage['auth'] = 'false';
        logout();
        break;
      case 403:
        message = 'Non sei autorizzato.';
        errorVariant = 'error';
        break;
      case 500:
        message = "C'è stato un errore del server, i tecnici sono già al lavoro per risolvere il problema.";
        errorVariant = 'error';
        break;
      case 502:
        message = "C'è stato un errore di un sistema collegato, i tecnici sono già al lavoro per risolvere il problema.";
        errorVariant = 'error';
        break;

      default:
        break;
    }

    dispatch(
      openSnackbar({
        open: true,
        message,
        variant: errorVariant,
        alert: {
          color: 'warning'
        },
        close: false,
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    );
  };
  return { handleError };
};
