import { useEffect, useRef, useState } from 'react';
import { refetchNotify } from 'store/reducers/helpers';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CheckCircleOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import { useServer } from 'hooks/useServer';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { NotificationDTO } from 'types/dto/notification.dto';
import { RootState, useDispatch, useSelector } from 'store';
import { formatSorting } from 'utils/tableHelpers';
import useAuth from 'hooks/useAuth';
import { CountedData } from '../../../../types/counted-data';
// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();

  const anchorRef = useRef<any>(null);
  const [unread, setUnRead] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [dataToSend] = useState<{
    skip: number;
    take: number;
    orderBy?: string;
    filterBy?: string;
    search?: string;
  }>({
    skip: 0,
    take: 6,
    orderBy: formatSorting.code([{ id: 'at', desc: true }])
  });
  const [notifyList, setNotifyList] = useState<NotificationDTO[]>([]);
  const [notifySelected, setNotifySelected] = useState<number | null>(null);
  const helpers = useSelector((state: RootState) => state.helpers);
  const { data, refetch } = useServer<CountedData<NotificationDTO>>('get', '/notifications', 'notification', dataToSend);
  const patchNotify = useServer('put', `/notifications/readings`, `notification-${notifySelected}`, { ids: [notifySelected] }, false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const markAsRead = (notify: NotificationDTO) => {
    if (!notify.readAt) setNotifySelected(notify.id);
  };

  const goToAllNotifications = () => {
    setOpen(false);
    navigate('notifications');
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  useEffect(() => {
    if (!data && auth.isLoggedIn) {
      refetch().then();
    }
  }, [data, auth.isLoggedIn]);

  useEffect(() => {
    console.log('Data');
    if (data) {
      setNotifyList(data.data.data);
      if (data.data.unread) setUnRead(data.data?.unread);
    } else {
      setNotifyList([]);
      setUnRead(0);
    }
  }, [data]);

  useEffect(() => {
    if (notifySelected && unread) {
      patchNotify.refetch().then();
      setUnRead(unread - 1);
      setNotifySelected(null);
    }
  }, [notifySelected]);

  useEffect(() => {
    if (helpers.refetchNotify) {
      refetch().then();
      dispatch(refetchNotify(false));
    }
  }, [helpers.refetchNotify]);

  useEffect(() => {
    console.log('Set unread', unread);
  }, [unread]);
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={unread} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifiche"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <Tooltip title="Mark as all read">
                      <IconButton color="success" size="small" onClick={() => setUnRead(0)}>
                        <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {notifyList.map((notify) => {
                      const { resource, message, at } = notify;
                      const color = resource === 'apps/logs' ? 'error' : 'success';
                      const icon = resource === 'apps/logs' ? <SettingOutlined /> : <MessageOutlined />;
                      const minuteAgo = dayjs().diff(dayjs(at), 'minute');
                      return (
                        <Box key={notify.id}>
                          <ListItemButton
                            onClick={() => {
                              markAsRead(notify);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: `${color}.main`,
                                  bgcolor: `${color}.lighter`,
                                  border: notify.readAt || notifySelected === notify.id ? '' : '1px solid orange'
                                }}
                              >
                                {icon}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="h6">{message}</Typography>} secondary={`${minuteAgo} min fa`} />
                            <ListItemSecondaryAction>
                              <Typography variant="caption" noWrap>
                                {dayjs(at).format('DD/MM/YYYY HH:mm')}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                          <Divider />
                        </Box>
                      );
                    })}

                    <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }} key={'notify-button'}>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary" onClick={goToAllNotifications}>
                            Vedi tutte
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
