import { Box, Button, IconButton, Typography } from '@mui/material';
// import dayjs from 'dayjs';
import MaterialReactTable, { MaterialReactTableProps, MRT_TableState } from 'material-react-table';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountedData } from '../../types/counted-data';
import { UseQueryResult } from 'react-query';
import { HelpersProps } from '../../store/reducers/helpers';
import { AxiosResponse } from 'axios';
import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import CustomTooltip from 'components/custom/Tooltip';
import { FilterOutlined, ReloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

export interface TecnoTableProps<D extends Record<string, any>> {
  name: string;
  countedData?: CountedData<D>;
  columns: MaterialReactTableProps<D>['columns'];
  refetch: UseQueryResult<AxiosResponse<CountedData<D>>>['refetch'];
  isLoading: boolean;
  pagination: MRT_TableState<D>['pagination'];
  onPaginationChange: MaterialReactTableProps<D>['onPaginationChange'];
  onSortingChange: MaterialReactTableProps<D>['onSortingChange'];
  sorting: MRT_TableState<D>['sorting'];
  onFiltersChange: MaterialReactTableProps<D>['onColumnFiltersChange'];
  filters: MRT_TableState<D>['columnFilters'];
  onGlobalFilterChange: MaterialReactTableProps<D>['onGlobalFilterChange'];
  globalFilter: MRT_TableState<D>['globalFilter'];
  actions?: React.ReactNode;
  onShareAction: () => void;
  shareData?: HelpersProps['shareData'];
  renderDetail?: MaterialReactTableProps<D>['renderDetailPanel'];
}

export const TecnoTable = <D extends Record<string, any>>({
  name,
  countedData,
  columns,
  refetch,
  isLoading,
  pagination,
  onPaginationChange,
  onSortingChange,
  sorting,
  onFiltersChange,
  filters,
  onGlobalFilterChange,
  globalFilter,
  actions,
  onShareAction,
  shareData,
  renderDetail
}: TecnoTableProps<D>) => {
  const tableRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState<string>();
  const { headerHeight } = useSelector((state: any) => state.helpers);
  const { data, totalCount, filteredCount } = countedData ?? {};
  const newData = useRef<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (newData.current !== data?.length) {
      newData.current = data?.length ?? 0;
    }
  }, [data]);

  useEffect(() => {
    if (tableRef.current && headerHeight) {
      // @ts-ignore
      const topToolbarHeight = tableRef.current.refs.topToolbarRef.current.clientHeight;
      // @ts-ignore
      const bottomToolbarHeight = tableRef.current.refs.bottomToolbarRef.current.clientHeight;
      setBodyHeight(`calc(100vh - ${headerHeight + topToolbarHeight + bottomToolbarHeight}px)`);
    }
  }, [tableRef, headerHeight]);

  useEffect(() => {
    if (tableRef.current && isLoading) {
      // @ts-ignore
      tableRef.current.options.localization.noRecordsToDisplay = `Caricamento in corso...`;
    } else if (tableRef.current && !isLoading) {
      // @ts-ignore
      tableRef.current.options.localization.noRecordsToDisplay = `Nessun risultato da mostrare`;
    }
  }, [isLoading]);

  return (
    <MaterialReactTable
      layoutMode="semantic"
      tableInstanceRef={tableRef}
      columns={columns}
      data={data ?? []}
      localization={MRT_Localization_IT}
      manualPagination
      onPaginationChange={onPaginationChange}
      manualSorting
      enableMultiSort
      manualFiltering={true}
      onColumnFiltersChange={onFiltersChange}
      columnFilterModeOptions={null}
      onSortingChange={onSortingChange}
      onGlobalFilterChange={onGlobalFilterChange}
      //Controllare lo stile
      enableColumnActions={true}
      enableToolbarInternalActions={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableStickyHeader
      memoMode="table-body"
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 20, 50, 100, 200, 1000, 10000]
      }}
      enableRowActions
      positionActionsColumn="last"
      renderRowActions={({ row }) =>
        row.original.appId && row.original.logId ? (
          <Button variant="contained" size="small" color="primary" onClick={() => navigate(`${row.original.appId}/${row.original.logId}`)}>
            Dettagli
          </Button>
        ) : (
          <Button variant="contained" size="small" color="primary" onClick={() => navigate(`${row.original.id}`)}>
            Dettagli
          </Button>
        )
      }
      muiTableContainerProps={{
        sx: {
          //@ts-ignore
          height: !bodyHeight ? `calc(100vh - ${headerHeight + 67 + 68}px)` : bodyHeight,
          bgcolor: 'secondary.A100'
        }
      }}
      rowCount={filteredCount ?? 0}
      state={{
        pagination,
        sorting,
        globalFilter,
        columnFilters: filters,
        showColumnFilters: true,
        showGlobalFilter: true,
        density: 'compact'
      }}
      muiTablePaperProps={{
        elevation: 0
      }}
      muiSearchTextFieldProps={{
        variant: 'outlined'
      }}
      muiTableHeadCellFilterTextFieldProps={({ column }) => ({
        variant: 'outlined',
        size: 'small',
        placeholder: column.columnDef.header,
        sx: {
          minWidth: 'none',
          '.MuiInputBase-root': {
            p: 0,
            bgcolor: 'secondary.A100'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: filters.find((filter: any) => filter.id === column.id) ? 'primary.light' : ''
            }
          }
        }
      })}
      muiTableHeadCellProps={{
        sx: {
          '.css-1w86f15': {
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2px'
          }
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          textAlign: 'center'
        }
      }}
      renderTopToolbarCustomActions={({ table }) => {
        const handleResetFilters = () => {
          table.resetColumnFilters();
          if (onFiltersChange) onFiltersChange([]);
        };
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
            {actions ? actions : <Typography variant="h3">Tabella {name}</Typography>}
            {refetch && (
              <CustomTooltip arrow title="Ricarica i dati" color="secondary">
                <IconButton color="secondary" onClick={() => refetch()}>
                  <ReloadOutlined />
                </IconButton>
              </CustomTooltip>
            )}
            <CustomTooltip arrow title="Reimposta filtri" color="secondary">
              <span>
                <IconButton disabled={!filters.length} color="secondary" onClick={handleResetFilters}>
                  <FilterOutlined />
                </IconButton>
              </span>
            </CustomTooltip>
            {onShareAction && (
              <CustomTooltip arrow title="Condividi" color="secondary">
                <span>
                  <IconButton color="secondary" onClick={onShareAction}>
                    <ShareAltOutlined />
                  </IconButton>
                </span>
              </CustomTooltip>
            )}
          </Box>
        );
      }}
      renderBottomToolbarCustomActions={() => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
          <Typography variant="h6">Totale: {totalCount ?? 0}</Typography>
          <Typography variant="h6">Filtrati: {filteredCount ?? 0}</Typography>
        </Box>
      )}
      icons={{
        SearchIcon: () => <SearchOutlined fontSize="small" />,
        CloseIcon: () => <CloseOutlined fontSize="small" />
      }}
    />
  );
};
