import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { usePermissions } from 'hooks/usePermissions';

interface AuthPermissionProps {
  resource: string | undefined;
  type?: string;
  permission: string | undefined;
  children: ReactJSXElement;
}

const AuthPermission = ({ children, resource, type, permission }: AuthPermissionProps) => {
  const { checkPermission } = usePermissions();
  return checkPermission(resource, permission, { type }) ? children : <></>;
};

export default AuthPermission;
