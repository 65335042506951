import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import useAxiosInstance from '../../hooks/useAxiosInstance';
import Fade from '@mui/material/Fade';
// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

const ShowLoader = () => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [loadingInterval, setLoadingInterval] = useState<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const axiosIstance = useAxiosInstance();

  useEffect(() => {
    axiosIstance.axiosInstance.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
        return error;
      }
    );
    axiosIstance.axiosInstance.interceptors.response.use(
      (config) => {
        setLoading(false);
        return config;
      },
      (error) => {
        setLoading(false);
        return error;
      }
    );
  }, []);

  const simulateLoading = () => {
    if (loadingInterval) {
      clearInterval(loadingInterval);
    }
    setProgress(0);

    // memorizza l'intervallo dell'animazione di caricamento in uno stato locale
    setLoadingInterval(
      setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(loadingInterval!); // cancella l'intervallo dell'animazione di caricamento
            return 0;
          }
          return prevProgress + 10;
        });
      }, 1000)
    );
  };

  const animateToEnd = () => {
    // cancella l'intervallo dell'animazione di caricamento se esiste
    if (loadingInterval) {
      clearInterval(loadingInterval);
    }

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 80) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 1);

    // memorizza l'intervallo dell'animazione di fine in uno stato locale
    setLoadingInterval(interval);
  };

  useEffect(() => {
    if (loading) {
      simulateLoading();
    } else {
      animateToEnd();
    }
  }, [loading]);

  useEffect(() => {
    // console.log('progress: ', progress);
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 500);
    }
  }, [progress]);

  return (
    <LoaderWrapper>
      <Fade in={progress > 0}>
        <LinearProgress
          color="primary"
          sx={{
            height: 2.5,
            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
            background: 'none'
          }}
          variant={'determinate'}
          value={progress}
        />
      </Fade>
    </LoaderWrapper>
  );
};

export default ShowLoader;
