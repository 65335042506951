// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

// auth provider
import { AuthProvider } from 'contexts/AuthContext';
import { SnackbarProvider } from 'notistack';
import { Box, Typography } from '@mui/material';
import Offline from 'components/custom/Offline';
import Idle from 'components/custom/Idle';
import useConfig from 'hooks/useConfig';
import { useEffect } from 'react';
import { AxiosInstanceProvider } from './contexts/AxiosInstanceContext';

require('dayjs/locale/it');

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

export const queryClient = new QueryClient();

const App = () => {
  dayjs.locale('it');

  const { onChangeFontFamily, onChangeEnvironment } = useConfig();
  const environment = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
  useEffect(() => {
    onChangeFontFamily(`'IBM Plex Sans', sans-serif`);
    onChangeEnvironment(environment);
  }, []);

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <AxiosInstanceProvider>
              <QueryClientProvider client={queryClient}>
                {/* @ts-ignore */}
                <SnackbarProvider maxSnack={5}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100vh',
                      display: { xs: 'flex' },
                      visibility: { xs: 'visible', md: 'hidden' },
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 99999999999,
                      bgcolor: 'secondary.A100',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 5
                    }}
                  >
                    <Typography variant='h2'>L'app è disponibile solamente per schermi superiori ai 10".</Typography>
                  </Box>
                  <Routes />
                  <Snackbar />
                  <Offline />
                  <Idle />
                </SnackbarProvider>
              </QueryClientProvider>
            </AxiosInstanceProvider>
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
