import { useEffect } from 'react';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useLocation, useNavigate } from 'react-router';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, authTokenLogin, user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(
    () => {
      if (!isLoggedIn && localStorage['accessToken'] && localStorage['accessToken'] !== '') {
        authTokenLogin();
      } else if (!isLoggedIn && localStorage['accessToken'] === '') {
        navigate('/login', { replace: true });
      } else if (isLoggedIn && state?.previous !== '/' && localStorage['accessToken'] && localStorage['accessToken'] !== '') {
        authTokenLogin();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, localStorage['accessToken']]
  );

  return isLoggedIn && user ? children : <></>;
};

export default AuthGuard;
