// material-ui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import IconButton from 'components/@extended/IconButton';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import useConfig from 'hooks/useConfig';

// ==============================|| HEADER CONTENT - DARK LIGHT MODE ||============================== //

const DarkLightMode = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();

  const handleToggle = () => {
    onChangeMode(mode === 'dark' ? 'light' : 'dark');
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'grey.100' }}
        onClick={handleToggle}
      >
        {mode === 'dark' ? <DarkModeOutlined /> : <LightModeOutlined />}
      </IconButton>
    </Box>
  );
};

export default DarkLightMode;
