import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, authTokenLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(
    () => {
      if (!isLoggedIn && localStorage['accessToken'] && localStorage['accessToken'] !== '') {
        authTokenLogin();
      } else if (isLoggedIn && localStorage['accessToken'] && localStorage['accessToken'] !== '') {
        navigate('/dashboard', { replace: true, state: { previous: '/' } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoggedIn, navigate]
  );

  return !isLoggedIn ? children : <></>;
};

export default GuestGuard;
