import config from 'config';
import { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

export const socket = io(config.serverPath, {
  path: '/tcnbisckadpt',
  transports: ['websocket', 'polling'],
  auth: {
    token: localStorage['accessToken']
  },
  extraHeaders: {
    Authorization: `Bearer ${localStorage['accessToken']}`
  },
  reconnection: true,
  autoConnect: true
});
export const WebsocketContext = createContext<Socket>(socket);

export const WebsocketProvider = WebsocketContext.Provider;
