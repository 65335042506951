import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { createContext, useState } from 'react';

interface ConfirmationDialogProps {
  title: string;
  text: string;
  callback?: () => any;
  data?: {
    action?: string;
    value?: string | number;
  };
  open?: boolean;
}

export const askConfirmationState: ConfirmationDialogProps = {
  title: 'Sei sicuro?',
  text: 'Sei sicuro di voler eseguire questa operazione?',
  callback: () => {},
  open: false
};

export const AskConfirmationContext = createContext<any>(askConfirmationState);

export const AskConfirmationProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [callbackToExecute, setCallbackToExecute] = useState<any>(null);
  const [titleToShow, setTitleToShow] = useState<string>('Sei sicuro?');
  const [textToShow, setTextToShow] = useState<string>('Sei sicuro di voler eseguire questa operazione?');
  const [open, setOpen] = useState<boolean>(false);

  const askConfirmation = ({ title, text, callback, data }: ConfirmationDialogProps) => {
    if (callback) setCallbackToExecute(() => () => callback());
    if (title) setTitleToShow(title);
    if (text) setTextToShow(text);
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
    setCallbackToExecute(null);
    setTitleToShow('');
    setTextToShow('');
  };
  const handleConfirmation = () => {
    callbackToExecute();
    setOpen(false);
  };
  return (
    <AskConfirmationContext.Provider
      value={{
        askConfirmation,
        closeDialog,
        title: titleToShow,
        text: textToShow,
        action: handleConfirmation,
        open
      }}
    >
      {children}
      <AskConfirmationContext.Consumer>
        {({ open, title, text, action, closeDialog }) => (
          <Dialog sx={{ '& .MuiDialog-paper': { width: 500, maxHeight: 435 } }} maxWidth={'sm'} open={open ?? false}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{text}</DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDialog}>
                Annulla
              </Button>
              <Button variant="contained" onClick={action} sx={{ mr: 0.5 }}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </AskConfirmationContext.Consumer>
    </AskConfirmationContext.Provider>
  );
};
