// types
import { createSlice } from '@reduxjs/toolkit';

export interface HelpersProps {
  headerHeight: number;
  refetchUsers: boolean;
  refetchErrors: boolean;
  refetchNotify: boolean;
  maintenance: boolean;
  refetchMaintenance: boolean;
  loader: boolean;
  shareData:
    | {
        path: string;
        additionalData: Record<string, any>;
        code?: string;
      }
    | undefined;
  confirmationDialog:
    | {
        title: string;
        text: string;
        data?: {
          action?: string;
          value?: string | number;
        };
      }
    | undefined;
}

// initial state
const initialState: HelpersProps = {
  headerHeight: 0,
  refetchUsers: false,
  refetchErrors: false,
  refetchNotify: false,
  maintenance: false,
  shareData: undefined,
  refetchMaintenance: false,
  confirmationDialog: undefined,
  loader: false
};

// ==============================|| SLICE - HELPERS ||============================== //

const helpers = createSlice({
  name: 'helpers',
  initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
    refetchUsers: (state, action) => {
      state.refetchUsers = action.payload;
    },
    refetchErrors: (state, action) => {
      state.refetchErrors = action.payload;
    },
    refetchNotify: (state, action) => {
      state.refetchNotify = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    refetchMaintenance: (state, action) => {
      state.refetchMaintenance = action.payload;
    },
    setShareData: (state, action) => {
      state.shareData = action.payload;
    },
    setConfirmationDialog: (state, action) => {
      state.confirmationDialog = action.payload;
    }
  }
});

export default helpers.reducer;

export const {
  setHeaderHeight,
  refetchUsers,
  refetchErrors,
  refetchNotify,
  refetchMaintenance,
  setShareData,
  setMaintenance,
  setConfirmationDialog,
  setLoader
} = helpers.actions;
