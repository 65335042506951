import axios, { AxiosInstance } from 'axios';
import useAuth from '../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useError } from '../hooks/useError';
import { setLoader } from '../store/reducers/helpers';
import React, { createContext, useEffect } from 'react';

const apiVersion: string = process.env.REACT_APP_API_VERSION;
const serverUrl: string = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  baseURL: serverUrl + 'server/' + apiVersion,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

const AxiosInstanceContext = createContext<{ axiosInstance: AxiosInstance } | null>(null);

export const AxiosInstanceProvider = ({ children }: { children: React.ReactElement }) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { handleError } = useError();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        const refreshToken = localStorage['refreshToken'];
        if (error.response?.data?.message === 'invalid_token') {
          return axios
            .post(serverUrl + 'auth/' + apiVersion + '/refresh', undefined, {
              headers: { Authorization: `Bearer ${refreshToken}` }
            })
            .then(({ data }) => {
              localStorage['accessToken'] = data.accessToken;
              localStorage['refreshToken'] = data.refreshToken;
              originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
              return axios(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (error.response?.data?.message === 'Unauthorized') {
          logout();
          handleError(error);
        } else {
          console.log('Errore chiamata server');
          dispatch(setLoader(false));
          handleError(error);
          return Promise.reject(error);
        }
      }
    );
  }, []);

  return (
    <AxiosInstanceContext.Provider
      value={{
        axiosInstance
      }}
    >
      {children}
    </AxiosInstanceContext.Provider>
  );
};

export default AxiosInstanceContext;
