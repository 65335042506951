// types
import { DefaultConfigProps } from 'types/config';
import pack from '../package.json';
import * as process from 'process';
import dayjs from 'dayjs';

export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

// ==============================|| THEME CONFIG  ||============================== //
const config: DefaultConfigProps = {
  defaultPath: process.env.REACT_APP_DEFAULT_PATH,
  fontFamily: `'IBM Plex Sans', sans-serif`,
  i18n: 'it',
  miniDrawer: false,
  container: true,
  mode: 'dark',
  presetColor: 'theme1',
  themeDirection: 'ltr',
  serverUrl: process.env.REACT_APP_SERVER_URL,
  serverPath: process.env.REACT_APP_SERVER_PATH,
  siteUrl: process.env.REACT_APP_SITE_URL,
  APIVersion: process.env.REACT_APP_API_VERSION,
  appName: 'Tecnonews',
  copyright: 'Tecnonews srl',
  version: `${pack.version}`,
  lastUpdate: dayjs(process.env.REACT_APP_LAST_UPDATE).toDate(),
  year: new Date().getFullYear(),
  environment: process.env.REACT_APP_SERVER || process.env.NODE_ENV
};

export default config;
