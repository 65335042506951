// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ handleDrawerToggle }: any) => {
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        },
        height: '100%'
      }}
    >
      <Navigation handleDrawerToggle={handleDrawerToggle} />
    </SimpleBar>
  );
};

export default DrawerContent;
