import useConfig from 'hooks/useConfig';

const useImageLink = () => {
  const { serverUrl, APIVersion } = useConfig();
  const formatImage = (src: string, size?: string | number) => {
    return src ? serverUrl + 'uploads/' + APIVersion + '/' + src + '?w=' + (size ?? '100') : undefined;
  };
  return { formatImage };
};

export default useImageLink;
