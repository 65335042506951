import { Params } from 'react-router';
import routes from 'utils/routes/routes.json';

export const formatText = (route: string | undefined, params?: Readonly<Params<string>>) => {
  // @ts-ignore
  if (routes[route]) {
    // @ts-ignore
    return routes[route];
  }
  if (route?.includes('/hotel/')) {
    if (route.includes('dashboard')) {
      return 'Dashboard';
    } else if (route.includes('clients')) {
      if (route.includes('new')) {
        return 'Nuovo Cliente';
      } else if (params?.clientId) {
        return 'Profilo Cliente';
      }
      return 'Clienti';
    } else if (route.includes('rooms')) {
      return 'Camere';
    } else if (route.includes('info')) {
      return 'Informazioni';
    } else if (route.includes('bookings')) {
      return 'Prenotazioni Hotel';
    }
  }
  return route;
};
