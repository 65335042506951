import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { usePermissions } from 'hooks/usePermissions';
import { useServer } from 'hooks/useServer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refetchMaintenance as triggerRefetchMaintenance, setMaintenance } from 'store/reducers/helpers';
import { MaintenanceDTO } from 'types/dto/maintenance.dto';
import { RootStateProps } from 'types/root';

const Maintenance: React.FC = () => {
  const { data, refetch } = useServer<{ data: Record<string, MaintenanceDTO> }>('get', '/state', 'state');
  const { refetchMaintenance } = useSelector((state: RootStateProps) => state.helpers);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { checkPermission } = usePermissions();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data?.data.data.maintenance) {
      handleOpen();
      dispatch(setMaintenance(true));
    } else {
      handleClose();
      dispatch(setMaintenance(false));
    }
  }, [data]);

  useEffect(() => {
    if (refetchMaintenance) {
      refetch();
      dispatch(triggerRefetchMaintenance(false));
    }
  }, [refetchMaintenance]);
  return (
    <>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Server in manutenzione</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Il server è in manutenzione, si è pregati di attendere.</DialogContentText>
        </DialogContent>
        {checkPermission('maintenance', 'read', { type: 'item' }) && (
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Chiudi
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default Maintenance;
