import { useDispatch } from 'react-redux';
import { setLoader } from '../store/reducers/helpers';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import useAxiosInstance from './useAxiosInstance';

const dataMethods = ['post', 'put', 'patch'];
const paramsMethods = ['get'];

export const useServer = <T = any>(
  method: string,
  endpoint: string,
  key: string,
  dataToSend?: object | any[] | null | string,
  condition: boolean = true,
  file: boolean = false,
  cacheTime: number | undefined = undefined
) => {
  // const axiosInstance = useAxiosInstance();
  const { axiosInstance } = useAxiosInstance();
  const dispatch = useDispatch();

  return useQuery<AxiosResponse<T>>(
    [key, dataToSend],
    async () => {
      dispatch(setLoader(true));

      let fileToSend = false;
      if (typeof dataToSend === 'object') {
        for (let key in dataToSend) {
          // @ts-ignore
          if (dataToSend[key] instanceof window.File) {
            fileToSend = true;
          }
        }
      }

      const response = await axiosInstance({
        method,
        url: endpoint,
        data: dataMethods.includes(method.toLowerCase()) ? dataToSend : {},
        params: paramsMethods.includes(method.toLowerCase()) ? dataToSend : {},
        headers: {
          Authorization: `Bearer ${localStorage['accessToken']}`,
          // @ts-ignore
          'Content-Type': file || fileToSend ? 'multipart/form-data' : 'application/json'
        }
      });

      if (response) {
        dispatch(setLoader(false));
      }

      return response;
    },
    {
      keepPreviousData: true,
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: condition,
      cacheTime
    }
  );
};
