// types
import { createSlice } from '@reduxjs/toolkit';

export interface PermissionsProps {
  permissions: {
    resource: string;
    permissions: string[];
  }[];
}

// initial state
const initialState: PermissionsProps = {
  permissions: []
};

// ==============================|| SLICE - HELPERS ||============================== //

const permissions = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    }
  }
});

export default permissions.reducer;

export const { setPermissions } = permissions.actions;
