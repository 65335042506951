import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Fade, Grow, Slide, SlideProps, Typography } from '@mui/material';
// import MuiSnackbar from '@mui/material/Snackbar';
// project-import
// import IconButton from './IconButton';
// assets
// import { CloseOutlined } from '@ant-design/icons';
import { KeyedObject, RootStateProps } from 'types/root';
import { closeSnackbar as closeSnackbarReducer } from 'store/reducers/snackbar';
import { useSnackbar } from 'notistack';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootStateProps) => state.snackbar);
  const { anchorOrigin, message, open, transition, variant, persist, key } = snackbar;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    dispatch(closeSnackbarReducer(key));
  };

  const CustomComponent = () => {
    return (
      <Box width={250}>
        {Array.isArray(message) ? (
          <>
            <Typography variant="h5">Errore</Typography>
            {message.map((item, index) => (
              <Typography key={index}>
                {message.length > 1 ? '• ' : ''}
                {item}
              </Typography>
            ))}
          </>
        ) : (
          message
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (open) {
      enqueueSnackbar(<CustomComponent />, {
        anchorOrigin,
        TransitionComponent: animation[transition],
        variant,
        onClose: handleClose,
        autoHideDuration: 5000,
        persist,
        key
        // preventDuplicate: true
      });
    } else {
      key && closeSnackbar(key);
    }
  }, [open]);

  return <></>;
};

export default Snackbar;
