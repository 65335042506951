import { useContext } from 'react';
import AxiosInstanceContext from '../contexts/AxiosInstanceContext';

const useAxiosInstance = () => {
  const context = useContext(AxiosInstanceContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useAxiosInstance;
