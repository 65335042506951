import { FilterProps } from 'types/dataToSend';

export const formatFilters = {
  code: (filters: FilterProps[] | undefined, types?: { contains?: string[]; equals?: string[]; in?: string[]; range?: string[] }) => {
    if (!filters?.length) return undefined;
    return JSON.stringify(
      filters.map((el: any) => {
        let value: object = { contains: el.value };
        if (types?.equals?.includes(el.id)) {
          if (el.value === 'empty') value = { equals: null };
          else if (el.value === 'not-empty') value = { not: null };
          else value = { equals: el.value };
        }
        if (types?.in?.includes(el.id)) {
          if (el.value === 'empty') {
            value = { in: [] };
          } else Array.isArray(el.value) ? (value = { in: el.value }) : (value = { in: [el.value] });
        }
        if (types?.range?.includes(el.id)) value = { gte: el.value[0], lte: el.value[1] };

        return {
          field: el.id,
          value
        };
      })
    );
  },
  decode: (filters: string | undefined) => {
    filters = filters ? JSON.parse(filters) : filters;
    return Array.isArray(filters)
      ? filters.map(({ field, value }: any) => {
          let newValue: string | string[] = '';
          console.log(value);

          if (value.not === null) {
            newValue = 'not-empty';
          }
          if (value.contains || value.equals) {
            newValue = value.contains ?? value.equals;
            if (newValue == null) newValue = 'empty';
          }
          if (value.in) {
            if (!value.in.length) {
              value.in = 'empty';
            }
            newValue = value.in;
          }
          if (value.gte || value.lte) {
            newValue = [value.gte ? value.gte : null, value.lte ? value.lte : null];
          }

          return {
            id: field,
            value: newValue
          };
        })
      : [];
  }
};

export const formatSorting = {
  code: (sorting: any[] | undefined) => {
    if (!sorting?.length) return undefined;
    return JSON.stringify(
      sorting.map((el: any) => ({
        field: el.id,
        sort: el.desc ? 'desc' : 'asc'
      }))
    );
  },
  decode: (sorting: string | undefined) => {
    sorting = sorting ? JSON.parse(sorting) : sorting;
    return Array.isArray(sorting)
      ? sorting.map(({ field, sort }: any) => {
          return {
            id: field,
            desc: sort === 'desc'
          };
        })
      : undefined;
  }
};
