import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project import
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import DarkLightMode from './DarkLightMode';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent: React.FC<{ title: string }> = ({ title }) => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const darkLightMode = useMemo(() => <DarkLightMode />, []);

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h3" sx={{ opacity: title ? 1 : 0, transition: 'opacity .2s' }}>
          {title}
        </Typography>
      </Box>
      {!matchesXs && darkLightMode}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {<Notification />}
      {/* <Message /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
