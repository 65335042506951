// import { Link } from 'react-router-dom';

// material-ui
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import construction from 'assets/images/maintenance/under-construction.svg';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';

// ==============================|| UNDER CONSTRUCTION - MAIN ||============================== //

function Unauthorized() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  return (
    <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh', py: 2 }}>
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 300, sm: 480 } }}>
          <img src={construction} alt="mantis" style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography align="center" variant="h1">
            Non hai i permessi per visualizzare questa pagina
          </Typography>
          <Typography color="textSecondary" align="center" sx={{ width: '85%' }}>
            Chiedi ad un amministratore di aggiornare i tuoi permessi.
          </Typography>
          {/* <Button component={Link} to={config.defaultPath} variant="contained">
            Back To Home
          </Button> */}
        </Stack>
        <Stack mt={3} spacing={2} justifyContent="center" alignItems="center">
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Unauthorized;
